import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Alles ist im Fluss. Modernes Webdesign muss eine scheinbar unendliche Vielfalt an Bildschirmgrößen berücksichtigen.
Mit `}<InlineCode mdxType="InlineCode">{`@media`}</InlineCode>{` Queries können wir Elemente auf der Grundlage von Merkmalen des Browser-Viewports
(Bildschirm-Breite etc.) gestalten.`}</p>
    <p>{`Was aber, wenn wir wiederverwendbare Komponenten erstellen möchten, die sich an ihren Container anpassen? Etwa eine
News-Karte, die sich über die gesamte Breite der Webseite erstrecken oder in eine schmale Spalte an der
Seite passen kann. Das geht jetzt!`}</p>
    <p>{`Mit `}<InlineCode mdxType="InlineCode">{`CSS Container Queries`}</InlineCode>{` können wir responsive, wiederverwendbare Komponenten entwerfen, die sich
an den verfügbaren Platz anpassen. Seit dem `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Mozilla/Firefox/Releases/110"
      }}>{`Release von Firefox 110`}</a>{`
wird das Feature nun von `}<a parentName="p" {...{
        "href": "https://caniuse.com/css-container-queries"
      }}>{`allen wichtigen Browsern unterstützt`}</a>{`. Das ändert alles!
Schauen wir uns an, wie es funktioniert.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABA5ws8Ej/AP/EABoQAAEFAQAAAAAAAAAAAAAAAAIAAQMREiL/2gAIAQEAAQUCF8tkkQtYSUjlUhdf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAEAABESIxMv/aAAgBAQAGPwLmVZjg/8QAGRAAAwEBAQAAAAAAAAAAAAAAAAEhEUGh/9oACAEBAAE/IeggSJDJlFi1ks0a3eM9GuIf/9oADAMBAAIAAwAAABB/H//EABcRAAMBAAAAAAAAAAAAAAAAAAARITH/2gAIAQMBAT8QjuDP/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERIVH/2gAIAQIBAT8QV1kfT//EAB0QAQADAAMAAwAAAAAAAAAAAAEAESExQVFhkaH/2gAIAQEAAT8QIKa5Sjd++oO1RssHZ+kolhRMLxmvPsJ0r7fKXXCuCf/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Übereinander gestapelte Kaffeedosen unterschiedlicher Größe.",
          "title": "Übereinander gestapelte Kaffeedosen unterschiedlicher Größe.",
          "src": "/static/cb04f22af185dd58f6f2c615a52932ba/e5166/pexels-pixabay-coffee-cans.jpg",
          "srcSet": ["/static/cb04f22af185dd58f6f2c615a52932ba/f93b5/pexels-pixabay-coffee-cans.jpg 300w", "/static/cb04f22af185dd58f6f2c615a52932ba/b4294/pexels-pixabay-coffee-cans.jpg 600w", "/static/cb04f22af185dd58f6f2c615a52932ba/e5166/pexels-pixabay-coffee-cans.jpg 1200w", "/static/cb04f22af185dd58f6f2c615a52932ba/b17f8/pexels-pixabay-coffee-cans.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Pixabay / pexels.com`}</em></p>
    <h2>{`Die Größe eines Containers abfragen`}</h2>
    <p>{`Dank Container Queries können wir Styles auf Elemente anwenden, die auf der Größe ihres Containers und nicht auf dem
Viewport basieren. Das Feature ist im CSS-Modul `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-contain-3/#container-queries"
      }}>{`CSS Containment Module Level 3`}</a>{`
definiert:`}</p>
    <blockquote lang="en">
    While media queries provide a method to query aspects of the user agent or device environment that a document is being
displayed in (such as viewport dimensions or user preferences), <strong>container queries</strong> allow testing aspects of
elements within the document (such as box dimensions or computed styles).
    </blockquote>
    <p>{`Wir können verschiedene Größenmerkmale von Containern wie `}<InlineCode mdxType="InlineCode">{`width`}</InlineCode>{`, `}<InlineCode mdxType="InlineCode">{`height`}</InlineCode>{`
oder `}<InlineCode mdxType="InlineCode">{`inline-size`}</InlineCode>{` abfragen. Die Spezifikation umfasst auch `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/css-contain-3/#style-container"
      }}>{`Container Style Queries`}</a>{`,
die jedoch von den Browsern noch nicht unterstützt werden.`}</p>
    <p>{`Um eine Komponente auf Basis ihrer Container-Größe zu stylen, müssen wir das übergeordnete Element mit der
CSS-Eigenschaft `}<InlineCode mdxType="InlineCode">{`container-type`}</InlineCode>{` ausweisen. Es wird empfohlen, den Container auch
mit `}<InlineCode mdxType="InlineCode">{`container-name`}</InlineCode>{` zu benennen. Hier ist ein Beispiel:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.news-card {
    container-type: inline-size;
    container-name: news-card;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ihr könnt den Container-Typ und -Namen auch auf einmal mit der CSS-Kurzform `}<InlineCode mdxType="InlineCode">{`container`}</InlineCode>{` setzen:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`.news-card {
    container: news-card / inline-size;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Jetzt können wir den Container mit der `}<InlineCode mdxType="InlineCode">{`@container`}</InlineCode>{` Deklaration abfragen und konditionale Styles
definieren. Zum Beispiel, wenn die Breite des Containers unter einem bestimmten Schwellenwert liegt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`@container news-card (max-width: 25rem) {
    .card-image {
        flex-basis: 100%;
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Demo: Responsive News-Karte`}</h2>
    <p>{`Ich habe eine `}<a parentName="p" {...{
        "href": "https://codepen.io/alexlehner86/pen/abaOdLw"
      }}>{`Demo auf Codepen`}</a>{` erstellt, die eine News-Karten-Komponente
zeigt. Wenn genügend Platz vorhanden ist, legt die Karte ihren Inhalt horizontal aus. Wenn die Breite des Containers unter
einen bestimmten Schwellenwert fällt, wird der Text unter das Bild verschoben.`}</p>
    <iframe scrolling="no" title="Code-Beispiel für CSS Container Queries" src="https://codepen.io/alexlehner86/embed/abaOdLw?default-tab=result" loading="lazy" allowtransparency="true">
    Öffne das Code-Beispiel <a href="https://codepen.io/alexlehner86/pen/abaOdLw">
    CSS Container Queries Demo</a> von Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    auf <a href="https://codepen.io" lang="en">CodePen</a>.
    </iframe>
    <h2>{`Weitere Lektüre`}</h2>
    <p>{`Es gibt noch viel mehr über Container Queries zu lernen. Seht euch die folgenden Anleitungen und Beispiele an:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ishadeed.com/article/container-queries-are-finally-here/"
        }}>{`“CSS container queries are finally here”, by Ahmad Shadeed`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://css-tricks.com/early-days-of-container-style-queries/"
        }}>{`“Early Days of Container Style Queries”, by Geoff Graham`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.oddbird.net/2022/08/18/cq-syntax/"
        }}>{`“Use the Right Container Query Syntax”, by Miriam Suzanne`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.matuzo.at/blog/2022/100daysof-day56/"
        }}>{`“Day 56: container queries”, by Manuel Matuzović`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://web.dev/cq-stable/"
        }}>{`“Container queries land in stable browsers”, by Una Kravets`}</a></li>
    </ul>
    <h2>{`Fazit`}</h2>
    <p>{`Ich bin total begeistert von CSS Container Queries! Dabei habe ich bisher kaum an der Oberfläche aller möglichen
Anwendungsfälle gekratzt. Mein Ziel ist es, das Feature in mehr und mehr Projekten zu verwenden und mein Verständnis
dafür laufend zu verbessern.`}</p>
    <p>{`Eines ist aus meiner Sicht fix: Container Queries werden die Art und Weise wie wir CSS schreiben nachhaltig verändern.
Welche Ideen und Anwendungsfälle habt ihr bereits mit dem Feature umgesetzt?`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      